<template>
  <div class="newDynamic">
    <div ref="box_title1" class="box_title">
      <h6>最新动态</h6>
    </div>
    <div
      class="list infinite-list"
      v-infinite-scroll="more"
      :infinite-scroll-disabled="disabled"
      infinite-scroll-delay="200"
      :infinite-scroll-distance="300"
    >

      <li v-for="item in DynamicList" :key="item.id">
        <img
          class="head"
          :src="`https://img.netopstec.com/website` + item.imgPath"
          alt=""
        />
        <div class="text">
          <p>{{ item.title }}</p>
          <span class="time">{{ item.publishTime }}</span>
          <div @click="openModel(item)" class="btn">
            <span>查看详情</span>
            <img src="https://prod.oss.netopstec.com/net-icon-jt3.png" alt="" />
          </div>
        </div>
      </li>
    </div>
    <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司mobile</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>

    <modelbox :showData="selectData" :show="showModel" :clone="handleClone">
    </modelbox>
  </div>
</template>

<script>
import modelbox from "../components/modelbox.vue";
import { get, post } from "@/utils/http";
export default {
  components: { modelbox },
  data() {
    return {
      showModel: false,
      DynamicList: [],
      selectData: {},
      pageSize: 4,
      disabled:false,
    };
  },
  created() {

    this.getDynamic();
  },
  methods: {
    handleClone() {
      this.showModel = false;
    },
    openModel(item) {
      this.selectData = item;
      this.showModel = true;
    },
    more() {
      if(!this.disabled){
        this.pageSize+=4
        this.getDynamic();
      }
    },
    getDynamic() {
      get("/recent-news", {
        pageNum: 1,
        pageSize: this.pageSize,
      }).then((res) => {
        if(this.DynamicList.length>=res.data.total){
          this.disabled = true
        }
        this.DynamicList = res.data.list;
        this.$beforeupdate;
      });
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    
    load(){
        console.log(1)
    }
  },
};
</script>
<style lang="less" scoped>
.newDynamic {
  position: relative;
}
@media screen and (min-width: 1100px) {
  .box_title {
    margin-top: 110px;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 1920px;
    height: 440px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      width: 100%;
      height: 100%;
      line-height: 440px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 40px;
      font-weight: 500;
      span {
        color: #982037;
      }
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 22px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .list {
    background: #5d1122;
    background-size: 100% 100%;
    display: flex;
    align-content: flex-start;
    padding: 150px 180px;
    flex-wrap: wrap;

    li {
      width: 360px;
      height: 500px;
      background: #fcfcfc;
      display: flex;
      margin: 110px 10px 0;
      flex-direction: column;
      opacity: 0;
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
      .head {
        width: 360px;
        height: 240px;
      }
      .text {
        display: flex;
        flex-direction: column;
        p {
          margin: 30px auto 0;
          text-align: center;
          width: 240px;
          font-size: 20px;
          font-weight: 900;
          color: #666666;
          line-height: 33px;
        }
        .time {
          margin: 20px auto 0;
          text-align: center;
          width: 240px;
          font-size: 22px;
          color: #666666;
          line-height: 33px;
        }
        .btn {
          cursor: pointer;
          margin: 20px auto 0;
          width: 214px;
          height: 53px;
          background: #fcfcfc;
          border: 1px solid #666666;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          img {
            margin-left: 20px;
            width: 10px;
            height: 14px;
          }
        }
      }
    }
  }
  .footer {
    width: 1920px;
    height: 175px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1100px) {
  .box_title {
    margin-top: 101px;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 750px;
    height: 324px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("https://prod.oss.netopstec.com/box_title-bg-h5.png")
      no-repeat center center;
    background-size: 100% 100%;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      width: 350px;
      height: 83px;
      line-height: 83px;
      align-items: center;
      text-align: center;
      opacity: 0;
      font-size: 40px;
      font-weight: 500;
      color: #ffffff;
      span {
        color: #982037;
      }
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 22px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .list {
    background: #5d1122;
    background-size: 100% 100%;
    display: flex;
    padding: 0 30px 120px;
    align-content: flex-start;
    flex-wrap: wrap;
    h2 {
      font-size: 20px;
      margin-top: 20px;
      width: 100%;
      text-align: center;
      color: white;
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    li {
      width: 300px;
      height: 410px;
      background: #fcfcfc;
      display: flex;
      margin: 60px 20px 0;
      flex-direction: column;
      opacity: 0;
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
      .head {
        height: 180px;
      }
      .text {
        display: flex;
        flex-direction: column;
        p {
          margin: 10px auto 0;
          text-align: center;
          width: 290px;
          font-size: 24px;
          color: #666666;
          line-height: 33px;
        }
        .time {
          margin: 10px auto 0;
          text-align: center;
          width: 210px;
          font-size: 20px;
          color: #666666;
          line-height: 33px;
        }
        .btn {
          cursor: pointer;
          margin: 20px auto 0;
          width: 200px;
          height: 50px;
          border-radius: 10px;
          background: #fcfcfc;
          border: 1px solid #666666;
          // border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 24px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          img {
            margin-left: 20px;
            width: 10px;
            height: 14px;
          }
        }
      }
    }
  }
  .footer {
    width: 750px;
    height: 216px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>